import get from 'lodash/get';
import PropTypes from 'prop-types';
import WithContentImpressionReporting from 'src/common/components/containerItems/shared/WithContentImpressionReporting';
import containerItemsCss from '../../components/containerItems/container-items.module.scss';
import ContainerTitle from '../../components/containerItems/shared/ContainerTitle';

function calculateGrid(containerItem, columnCount) {
  const gridColumns = [];
  let totalLinks = 0;

  for (let i = 0; i < columnCount; i++) {
    const currentColSize = Math.ceil(
      (get(containerItem, 'children.length', 0) - totalLinks) /
        (columnCount - i),
    );
    gridColumns.push(currentColSize);
    totalLinks += currentColSize;
  }

  return gridColumns;
}

const GridMatrixLayout = ({
  ChildPresentation,
  getNumberOfColumns,
  dataTestType,
  id,
  containerItem,
  breakpoint,
}) => {
  const columnCount = getNumberOfColumns(breakpoint);
  const children = get(containerItem, 'children', []);
  const gridColumns = calculateGrid(containerItem, columnCount);
  let childIndex = 0;

  return (
    <div
      id={id}
      data-testid={id}
      data-testcount={children.length}
      data-testtype={dataTestType}
    >
      <ContainerTitle
        containerItem={containerItem}
        className={containerItemsCss.contentSubHeader}
      />
      <div className="row">
        {gridColumns.map((numLinks, i) => {
          const node = (
            <div
              key={i}
              className={`col-xs-${12 / columnCount}`}
              data-testid={`guideItemColumn-${i}`}
            >
              {children
                .slice(childIndex, childIndex + numLinks)
                .map((child, index) => (
                  <WithContentImpressionReporting
                    key={child.guideId}
                    guideItem={child}
                  >
                    <ChildPresentation
                      key={child.guideId}
                      guideItem={child}
                      index={index + childIndex}
                      numLinksInMatrix={children.length}
                      numColumnsInMatrix={gridColumns.length}
                      breakpoint={breakpoint}
                    />
                  </WithContentImpressionReporting>
                ))}
            </div>
          );

          childIndex += numLinks;

          return node;
        })}
      </div>
    </div>
  );
};

GridMatrixLayout.propTypes = {
  ChildPresentation: PropTypes.func.isRequired,
  getNumberOfColumns: PropTypes.func.isRequired,
  dataTestType: PropTypes.string.isRequired,
  containerItem: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  breakpoint: PropTypes.number.isRequired,
};

GridMatrixLayout.defaultProps = {
  containerItem: {},
};

export default GridMatrixLayout;
