export function urlStringToComponents(urlString) {
  if (typeof urlString !== 'string') {
    return {};
  }

  try {
    const url = new URL(urlString);

    return {
      url: url.toString(),
      urlWithoutQueryParams: `${url.origin}${url.pathname}`,
      queryParams: Object.fromEntries(url.searchParams),
      queryString: url.search,
      pathname: url.pathname,
    };
  } catch (e) {
    return {};
  }
}

export function urlComponentsToString(urlString, queryParams) {
  try {
    const url = new URL(urlString);
    url.search = new URLSearchParams(queryParams).toString();
    return url.toString();
  } catch (e) {
    return urlString;
  }
}

export function getQueryParamsFromUrl(urlString) {
  try {
    const url = new URL(urlString);
    return Object.fromEntries(url.searchParams);
  } catch (e) {
    return {};
  }
}
