import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { childrenCanonicalModels } from 'src/common/utils/braze/constants';
import AdCell from '../childPresentations/AdCell';
import EpisodeCard from '../childPresentations/EpisodeCard';
import ItemCard from '../childPresentations/ItemCard';
import { Link } from '../childPresentations/Link';
import NowPlaying from '../childPresentations/NowPlaying';
import containerItemsCss from '../container-items.module.scss';
import ContainerTitle from '../shared/ContainerTitle';
import PromptSelector from '../shared/PromptSelector';
import WithContentImpressionReporting from '../shared/WithContentImpressionReporting';
import Default from './childPresentations/Default';
import PremiumTile from './childPresentations/PremiumTile';
import ScheduleTile from './childPresentations/ScheduleTile';
import Status from './childPresentations/Status';
import WebPrompt from './childPresentations/WebPrompt';

const presentationMap = {
  CompactStatus: Status,
  Link,
  EpisodeCard,
  ItemCard,
  ScheduleTile,
  Prompt: PromptSelector,
  WebPrompt,
  PremiumTile,
  NowPlaying,
  AdCell,
};

function getPresentation(presentation) {
  if (presentation) {
    return presentationMap[presentation.layout] || Default;
  }

  return Default;
}

const ListLayout = ({
  containerItem,
  selectedContentId,
  id,
  getStyles,
  matchUrl,
  breakpoint,
  isNowPlaying,
  isDiscord,
  index,
  containerTitle,
  containerTitleClassName,
  maxChildren,
}) => {
  const children = maxChildren
    ? get(containerItem, 'children', []).slice(0, maxChildren)
    : get(containerItem, 'children');

  return (
    <div
      id={id}
      data-testid={id}
      data-testtype="listLayout"
      data-testcount={children ? children.length : 0}
    >
      <ContainerTitle
        containerItem={containerItem}
        getStyles={getStyles}
        className={classNames(
          containerItemsCss.contentSubHeader,
          containerTitleClassName,
        )}
        containerTitle={containerTitle}
      />
      <div data-testid="containerGuideItemsContainer">
        {children &&
          children.map((child, i) => {
            // some children won't have guideId's (e.g., AdCells), so this guarantees a unique
            // key for those children as well (e.g., `Ad-1`)
            const key = [child.type, child.guideId, i + 1]
              .filter(Boolean)
              .join('-');
            // temp fix for NP until platform figures out the issue with the response
            const ItemToUse =
              isNowPlaying && index === 0 && i === 0
                ? NowPlaying
                : getPresentation(child.presentation);

            const itemToUse = (
              <ItemToUse
                key={key}
                guideItem={child}
                selectedContentId={selectedContentId}
                index={i}
                containerIndex={index}
                getStyles={getStyles}
                matchUrl={matchUrl}
                breakpoint={breakpoint}
                isDiscord={isDiscord}
              />
            );

            if (
              presentationMap[child.presentation?.layout] &&
              child.presentation?.layout !== childrenCanonicalModels.PremiumTile
            ) {
              return (
                <WithContentImpressionReporting key={key} guideItem={child}>
                  {itemToUse}
                </WithContentImpressionReporting>
              );
            }

            // We don't wrap with impression reporting when Default component is rendered, as it utilizes its own wrapper with reporting in it.
            return itemToUse;
          })}
      </div>
    </div>
  );
};

ListLayout.propTypes = {
  containerItem: PropTypes.object.isRequired,
  selectedContentId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  getStyles: PropTypes.func.isRequired,
  breakpoint: PropTypes.number.isRequired,
  matchUrl: PropTypes.string.isRequired,
  isNowPlaying: PropTypes.bool,
  isDiscord: PropTypes.bool,
  index: PropTypes.number,
  containerTitle: PropTypes.string,
  containerTitleClassName: PropTypes.string,
  maxChildren: PropTypes.number,
};

ListLayout.defaultProps = {
  getStyles: () => ({}),
};

export default ListLayout;
