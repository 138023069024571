import { errorLabels } from '../../constants/interestSelector';
import { getQueryParamsFromUrl } from '../../utils/urlHelpers';

export function parseSuccessDeeplink(successDeeplink) {
  const queryParams = getQueryParamsFromUrl(successDeeplink);
  const paramEntries = Object.entries(queryParams);

  // the deeplink should only have 1 query param
  if (paramEntries.length !== 1) {
    throw errorLabels.invalidSuccessDeepLinkParamCount;
  }

  const [paramKey, paramValue] = paramEntries[0];

  if (!paramValue) {
    throw errorLabels.successDeepLinkParamEmpty;
  }

  return {
    upsellLabelSuffix: `${paramKey}:${paramValue}`?.toLowerCase(),
    themeType: paramValue?.toLowerCase(),
  };
}
