import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  logCategoryActionLabel,
  logWebActivity,
} from 'src/common/actions/logging';
import cssVars from 'src/common/styles/variables';
import cssVariables from 'src/common/styles/variables';
import { validateAndGetHexColor } from 'src/common/utils/color';
import web from '../../../constants/analytics/categoryActionLabel/web';
import { LINE_BREAK_INSTRUCTION } from '../../../constants/localizations/shared';
import {
  imageAlignments,
  imageSizes,
  marginStyles,
  textWidths,
} from '../../../constants/styles';
import withGuideItemTracking from '../../../decorators/containerItems/withGuideItemTracking';
import ActionButton from '../../shared/button/ActionButton';
import CloseButton from '../../shared/svgIcons/CloseButton';
import css from './super-prompt.module.scss';

const PROMPT = 'prompt';

const imageSizeCss = {
  [imageSizes.Small]: css.imageSmall,
  [imageSizes.Large]: css.imageLarge,
  [imageSizes.XXLarge]: css.imageXXLarge,
};

const textWidthCss = {
  [textWidths.Medium]: css.textWidthMedium,
};

const imageAlignmentsCssMap = {
  [imageAlignments.Left]: css.imageLeft,
  [imageAlignments.Right]: css.imageRight,
};

// exported for testing
export class SuperPrompt extends Component {
  static propTypes = {
    guideItem: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    getStyles: PropTypes.func.isRequired,
    trackingRef: PropTypes.object.isRequired,
    closeGuideItem: PropTypes.func.isRequired, // unmounts the component along with Event Listener
    isDiscord: PropTypes.bool,
  };

  static defaultProps = {
    getStyles: () => ({}),
  };

  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
    };
    this.promptContainerHeight = null;
    this.onClose = this.onClose.bind(this);
    this.onXClose = this.onXClose.bind(this);
  }

  componentDidMount() {
    this.props.actions.logWebActivity(web.actions.show, PROMPT);
  }

  onClose(label) {
    const { guideItem, actions, trackingRef } = this.props;
    if (trackingRef) {
      this.promptContainerHeight = trackingRef.current.offsetHeight;
    }
    this.setState({
      isHidden: true,
    });
    const itemToken = guideItem?.context.token;
    const reportData = guideItem?.actions?.dismiss?.reportData || '';
    actions.logWebActivity(web.actions.tap, label);
    actions.logCategoryActionLabel(reportData, { itemToken });
  }

  onXClose() {
    this.onClose(`${PROMPT}-xClose`);
  }

  getPromptContainerProps(guideItem, itemStyles, dismissAction) {
    const { isDiscord } = this.props;
    const { backgroundColor, margins } = itemStyles;
    const promptContainerProps = {
      style: {
        backgroundColor: isDiscord
          ? cssVariables['--medium-grey']
          : validateAndGetHexColor(backgroundColor, cssVariables['--grey-3']),
        maxHeight: this.promptContainerHeight,
      },
    };

    const backgroundImage = guideItem?.properties?.backgroundImage?.imageUrl;
    if (backgroundImage) {
      promptContainerProps.style.backgroundImage = `url(${backgroundImage})`;
    }

    promptContainerProps.className = classNames(css.promptContainer, {
      [css.hidden]: this.state.isHidden,
      [css.withCloseIcon]: dismissAction,
      [css.noMargins]: marginStyles[margins],
    });

    return promptContainerProps;
  }

  render() {
    const { guideItem, getStyles, trackingRef, closeGuideItem, isDiscord } =
      this.props;
    const {
      title = '',
      subtitle,
      image,
      actions: buttonActions,
      style,
    } = guideItem;
    const itemStyles = getStyles(style);
    const {
      textWidth,
      textColor,
      imageAlignment,
      imageSize,
      titleTextColor,
      titleTextSecondaryColor,
      titleTextBackgroundColor,
      titleTextFont,
      titleTextFontSize,
      titleTextTopMargin,
      titleTextBottomMargin,
      subtitleTextFont,
    } = itemStyles;

    const navigateAction = buttonActions?.navigate;
    const dismissAction = buttonActions?.dismiss;
    const textColorToUse = validateAndGetHexColor(
      textColor,
      cssVariables['--secondary-color-5'],
    );
    const [primaryTitle, secondaryTitle] = title.split(LINE_BREAK_INSTRUCTION);
    const isImageOnLeft = imageAlignment === imageAlignments.Left;

    const titleContainerClassNames = classNames({
      [css[`titleContainerTopMargin${titleTextTopMargin}`]]: titleTextTopMargin,
      [css[`titleContainerBottomMargin${titleTextBottomMargin}`]]:
        titleTextBottomMargin,
    });
    const titleClassNames = classNames(css.title, {
      [css.multiLineTitle]: secondaryTitle,
      [css.withoutImage]: !image,
      [css[`titleSize${titleTextFontSize}`]]: titleTextFontSize,
      [css[`titleFont${titleTextFont}`]]: titleTextFont,
    });
    const primaryTitleStyles = {
      color: isDiscord
        ? cssVariables['--white']
        : validateAndGetHexColor(titleTextColor, textColorToUse),
      backgroundColor: validateAndGetHexColor(
        titleTextBackgroundColor,
        'transparent',
      ),
    };
    const secondaryTitleStyles = {
      color: isDiscord
        ? cssVariables['--white']
        : validateAndGetHexColor(titleTextSecondaryColor, textColorToUse),
      backgroundColor: validateAndGetHexColor(
        titleTextBackgroundColor,
        'transparent',
      ),
    };
    const subtitleClassNames = classNames(css.subtitle, {
      [css[`subtitleFont${subtitleTextFont}`]]: subtitleTextFont,
    });

    return (
      <div
        {...this.getPromptContainerProps(guideItem, itemStyles, dismissAction)}
        data-testid="superPrompt"
        ref={trackingRef}
        onAnimationEnd={closeGuideItem}
      >
        <div
          className={classNames(
            css.textAndButtonsContainer,
            textWidthCss[textWidth],
            {
              [css.withoutImage]: !image,
            },
            {
              [css.onLeftSide]: !isImageOnLeft,
            },
          )}
        >
          <div data-testid="textContainer">
            <div
              data-testid="titleContainer"
              className={titleContainerClassNames}
            >
              {primaryTitle && (
                <div className={titleClassNames} data-testid="title">
                  <span style={primaryTitleStyles}>{primaryTitle}</span>
                </div>
              )}
              {secondaryTitle && (
                <div className={titleClassNames} data-testid="secondaryTitle">
                  <span style={secondaryTitleStyles}>{secondaryTitle}</span>
                </div>
              )}
            </div>
            {subtitle && (
              <div
                className={subtitleClassNames}
                data-testid="subtitle"
                style={{
                  color: isDiscord ? cssVariables['--grey-6'] : textColorToUse,
                }}
              >
                {subtitle}
              </div>
            )}
          </div>
          {!!navigateAction?.destinations?.length && (
            <div data-testid="buttonsContainer">
              {navigateAction?.destinations?.map?.((buttonMetadata) => (
                <ActionButton
                  key={buttonMetadata?.destination}
                  buttonMetadata={buttonMetadata}
                  guideItem={guideItem}
                  getStyles={getStyles}
                  buttonActions={buttonActions}
                  onClose={this.onClose}
                  actionLabelPrefix={PROMPT}
                  source={PROMPT}
                />
              ))}
            </div>
          )}
        </div>
        {image && (
          <img
            src={image}
            alt="Prompt"
            className={classNames(
              css.image,
              imageSizeCss[imageSize],
              imageAlignmentsCssMap[imageAlignment],
            )}
            data-testid="promptImage"
          />
        )}
        {dismissAction && (
          <CloseButton
            className={css.closeIconClassName}
            onClick={this.onXClose}
            fill={cssVars['--cloud-grey']}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    logCategoryActionLabel,
    logWebActivity,
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default flow(
  connect(null, mapDispatchToProps),
  withGuideItemTracking,
)(SuperPrompt);
