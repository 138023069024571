import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

const AdSettingsContext = createContext();

/**
 * Context provider for ad settings. Primarily used in ContainerItems to inform AdCell.
 * @param isAdEligible {boolean} - determines whether to show ads to user
 * @param isProfilePage {boolean} - indicates whether the user is on a profile page
 * @param children - Any renderable react node that the context provider wraps
 */
function AdSettingsProvider({
  isAdEligible = true,
  isProfilePage = false,
  children,
}) {
  const value = useMemo(
    () => ({ isAdEligible, isProfilePage }),
    [isAdEligible, isProfilePage],
  );

  return (
    <AdSettingsContext.Provider value={value}>
      {children}
    </AdSettingsContext.Provider>
  );
}

AdSettingsProvider.propTypes = {
  isAdEligible: PropTypes.bool.isRequired,
  isProfilePage: PropTypes.bool,
  children: PropTypes.node,
};

/**
 * AdSettingsContext consumer convenience wrapper hook
 * @returns {object}
 */
function useAdSettings(logClientError) {
  const context = useContext(AdSettingsContext);

  if (!context) {
    logClientError({
      message: 'useAdSettings must be used within an AdSettingsProvider',
      context: {},
    });
  }

  return context || {};
}

export { AdSettingsProvider, useAdSettings };
