import { Accordion } from '@tunein/web-ui-components';
import PropTypes from 'prop-types';

import withCollapsibleState from '../../decorators/withCollapsibleState';
import CollapsibleLabel from './CollapsibleLabel';

export const CollapsibleItem = ({
  handleMoreClick,
  shouldShowMore,
  children,
  moreText,
  lessText,
  labelStyle,
  className,
}) => {
  if (!children) {
    return null;
  }

  return (
    <>
      <CollapsibleLabel
        shouldShowMore={shouldShowMore}
        onMoreInfoClick={handleMoreClick}
        labelStyle={labelStyle}
        className={className}
        lessText={lessText}
        moreText={moreText}
      />
      <Accordion isExpanded={shouldShowMore}>{children}</Accordion>
    </>
  );
};

CollapsibleItem.propTypes = {
  handleMoreClick: PropTypes.func.isRequired,
  shouldShowMore: PropTypes.bool.isRequired,
  children: PropTypes.node,
  moreText: PropTypes.node.isRequired,
  lessText: PropTypes.node.isRequired,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
};

export default withCollapsibleState(CollapsibleItem);
