import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
/* eslint-disable max-len */

const CloseButton = ({
  dataTestId = 'closeIcon',
  viewBox = '0 0 30 30',
  height = '30px',
  width = '30px',
  fill = cssVariables['--secondary-color-5'],
  strokeWidth = 1.5,
  className,
  onClick,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20L20 10"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <path
      d="M10 10L20 20"
      stroke={fill}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </svg>
);
CloseButton.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  dataTestId: PropTypes.string,
  viewBox: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default CloseButton;
