import keyMirror from 'src/common/utils/keyMirror';

export const playbackStatusIcons = keyMirror(
  'newPlaybackItem',
  'playedProgress1',
  'playedProgress2',
  'playedProgress3',
  'playedProgress4',
  'completedPlaybackItem',
);

export default {
  [playbackStatusIcons.newPlaybackItem]: 'icon-dot',
  [playbackStatusIcons.playedProgress1]: '',
  [playbackStatusIcons.playedProgress2]: '',
  [playbackStatusIcons.playedProgress3]: '',
  [playbackStatusIcons.playedProgress4]: '',
  [playbackStatusIcons.completedPlaybackItem]: '',
};
