import get from 'lodash/get';
import PropTypes from 'prop-types';
import GridMatrixLayout from 'src/common/decorators/containerItems/GridMatrixLayout';
import { isSmall, isXSmall } from 'src/common/utils/breakpoints';
import GuideItemLink from '../shared/GuideItemLink';
import guideItemCss from '../shared/guide-item.module.scss';
import css from './link.module.scss';

export const Link = ({ guideItem, index }) => {
  const title = get(guideItem, 'properties.seoInfo.title');
  return (
    <div
      className={css.container}
      data-testid={`guideItem-${index}`}
      data-testtype="link"
      data-nexttitle={title}
      data-nextguideitem={guideItem.guideId}
    >
      <GuideItemLink guideItem={guideItem} dataTestId="guideItemLink">
        <span
          title={guideItem.title}
          className={guideItemCss.guideItemTitleAlt}
          data-testid="guideItemTitle"
        >
          {guideItem.title}
        </span>
      </GuideItemLink>
    </div>
  );
};

Link.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export function linkMatrixGetNumberOfColumns(breakpoint) {
  if (isSmall(breakpoint)) return 4;
  if (isXSmall(breakpoint)) return 3;
  return 1;
}

function LinkWithMatrixLayout(props) {
  return (
    <GridMatrixLayout
      ChildPresentation={Link}
      getNumberOfColumns={linkMatrixGetNumberOfColumns}
      dataTestType="listPresentation"
      {...props}
    />
  );
}

export default LinkWithMatrixLayout;
