import mobileWeb from 'src/common/constants/analytics/categoryActionLabel/mobileWeb';

// Opens TuneIn app in app store application
export default async function sendToAppDownload(
  branchUrl,
  label,
  logMobileWebActivity,
) {
  const { actions } = mobileWeb;

  await logMobileWebActivity(actions.tap, label);
  window.location.replace(branchUrl);
}
