import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import monthsByIndex from 'src/common/utils/monthsByIndex';
import commonCss from '../../../../styles/common.module.scss';
import GuideItemLink from '../../shared/GuideItemLink';
import css from '../../shared/guide-item.module.scss';
import scheduleTileCss from './schedule-tile.module.scss';

const ScheduleTile = ({ guideItem, index }) => {
  const { actions, title, subtitle } = guideItem;
  const startDate = new Date(actions.schedule.localStartTime);
  const guideItemTitle = get(guideItem, 'properties.seoInfo.title');

  return (
    <div
      data-testid={`guideItem-${index}`}
      data-testtype="scheduleTile"
      data-nexttitle={guideItemTitle}
      data-nextguideitem={guideItem.guideId}
    >
      <GuideItemLink
        guideItem={guideItem}
        className={classNames('row', css.guideItemContainer)}
        dataTestId="guideItemLink"
      >
        <div data-testid="squareTileOverlay" className={commonCss.overlay}>
          <div
            data-testid="guideItemImageWrapper"
            className={classNames(
              css.guideItemImageWrapper,
              scheduleTileCss.dateWrapper,
            )}
          >
            <div
              data-testid="scheduleTileMonth"
              className={scheduleTileCss.month}
            >
              {monthsByIndex[startDate.getMonth()]}
            </div>
            <div className={scheduleTileCss.date} data-testid="scheduleTileDay">
              {startDate.getDate()}
            </div>
          </div>
          <div
            data-testid="guideItemTitleContainer"
            className={classNames('col-xs-12', css.guideItemTitlesContainer)}
          >
            <h2
              className={classNames(
                css.guideItemTitleOffset,
                css.guideItemTitleSingleLine,
              )}
              data-testid="guideItemTitle"
            >
              {title}
            </h2>
            {subtitle && (
              <p
                className={classNames(
                  css.guideItemTitleOffset,
                  css.guideItemSubtitle,
                )}
                data-testid="guideItemTitleSubtitle"
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </GuideItemLink>
    </div>
  );
};

ScheduleTile.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ScheduleTile;
