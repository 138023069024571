import classNames from 'clsx';
import flow from 'lodash/flow';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  closeNowPlayingDialog,
  closeNowPlayingDialogAndTrack,
  fetchNowPlayingItems,
} from 'src/common/actions/dialog';
import ChevronRight from 'src/common/components/shared/svgIcons/ChevronRight';
import {
  selectIsDiscord,
  selectIsMobile,
  selectUserAgent,
} from 'src/common/selectors/app';
import {
  selectIsVideoAdDialogOpen,
  selectNowPlayingGuideItem,
} from 'src/common/selectors/dialog';
import {
  selectIsExpiredWebSubscriber,
  selectIsUserSubscribed,
} from 'src/common/selectors/me';
import {
  selectCanChangePlaybackRate,
  selectCanScrub,
  selectGuideItemPathname,
  selectIsMediaAdLoaded,
  selectNowPlaying,
  selectNowPlayingRejectReasonKey,
  selectNowPlayingUpsell,
  selectParentGuideId,
  selectPlayerStatus,
  selectPositionInfo,
  selectTunedGuideId,
} from 'src/common/selectors/player';
import { seek } from '../../../actions/player';
import { ENABLE_PLAYBACK_SPEED_CONTROL } from '../../../constants/experiments/player';
import {
  ERROR_MESSAGE,
  LIVE,
  MEDIA_AD_SUBTITLE,
  MEDIA_AD_TITLE,
} from '../../../constants/localizations/player';
import { playerStatuses } from '../../../constants/playerStatuses';
import connectWithAuth from '../../../decorators/auth/connectWithAuth';
import connectWithExperiments from '../../../decorators/connectWithExperiments';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectIsDesktopNowPlayingEnabled } from '../../../selectors/config';
import cssVars from '../../../styles/variables';
import { is360 } from '../../../utils/breakpoints';
import createSubscribeLink from '../../../utils/subscription/createSubscribeLink';
import NowPlayingDialogAd from '../../ads/NowPlayingDialogAd';
import SpeedControl from '../../player/actionDrawer/SpeedControl';
import PlayButton from '../../player/controls/playButton/PlayButton';
import Scrubber from '../../player/controls/scrubber/Scrubber';
import VolumeControl from '../../player/controls/volume/VolumeControl';
import ClampText from '../../shared/ClampText';
import ImageWithDefault from '../../shared/ImageWithDefault';
import FavoriteControl from '../../shared/controls/favorite/FavoriteControl';
import FastForwardFifteenIcon from '../../shared/svgIcons/FastForwardFifteenIcon';
import LegacyCaretDown from '../../shared/svgIcons/LegacyCaretDown';
import RewindFifteenIcon from '../../shared/svgIcons/RewindFifteenIcon';
import UpsellRibbon from '../../subscription/UpsellRibbon';
import getNowPlayingContainerItemGuideId from '../utils/getNowPlayingContainerItemGuideId';
import css from './now-playing.module.scss';

const SOURCE = 'nowPlaying';
const idPrefix = 'nowPlayingDialog';

const getTitle = (nowPlaying, getLocalizedText) =>
  nowPlaying.rejectReasonKey
    ? getLocalizedText(nowPlaying.rejectReasonKey)
    : nowPlaying?.title || nowPlaying?.header?.title;
const getSubtitle = (nowPlaying) =>
  nowPlaying?.subtitle || nowPlaying?.header?.subtitle;

// exported for testing
export class NowPlaying extends Component {
  static propTypes = {
    // from parent
    matchUrl: PropTypes.string.isRequired,
    breakpoint: PropTypes.number.isRequired,
    hideDisplayAd: PropTypes.bool,

    // from mapDispatchToProps
    nowPlayingGuideItem: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    userAgent: PropTypes.string.isRequired,
    nowPlaying: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isMediaAdLoaded: PropTypes.bool.isRequired,
    playerStatus: PropTypes.string.isRequired,
    selectedContentId: PropTypes.string,
    upsell: PropTypes.object.isRequired,
    canShowUpsell: PropTypes.bool.isRequired,
    isUserSubscribed: PropTypes.bool.isRequired,
    canChangePlaybackRate: PropTypes.bool.isRequired,
    currentlyPlayingGuideId: PropTypes.string.isRequired,
    isExpiredWebSubscriber: PropTypes.bool.isRequired,
    guideItemPathname: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    canScrub: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    elapsedSeconds: PropTypes.number,
    nowPlayingRejectReasonKey: PropTypes.string.isRequired,

    // from connectWithExperiments
    [ENABLE_PLAYBACK_SPEED_CONTROL]: PropTypes.bool,
    isDesktopNowPlayingEnabled: PropTypes.bool.isRequired,
    isVideoAdDialogOpen: PropTypes.bool.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.state = {
      isTitleScrolling: false,
      isSubtitleScrolling: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.startScrolling = this.startScrolling.bind(this);
    this.subtitleOuterContainer = createRef();
    this.subtitleInnerContainer = createRef();
    this.titleOuterContainer = createRef();
    this.titleInnerContainer = createRef();
  }

  componentDidMount() {
    const { actions, currentlyPlayingGuideId, nowPlayingGuideItem } =
      this.props;

    // NOTE: We only want to fetch new nowPlayingItems in the NowPlaying child component under specific circumstances
    // i.e. With the NowPlayingDialog open, the user clicks to tune a new station listed in one of the avail containers
    // If the currentlyPlayingGuideId differs from the guideId in the NowPlaying containerItem, only then refresh data
    if (
      !isEmpty(nowPlayingGuideItem.containerItems) &&
      !nowPlayingGuideItem.isFetching &&
      currentlyPlayingGuideId &&
      currentlyPlayingGuideId !==
        getNowPlayingContainerItemGuideId(nowPlayingGuideItem.containerItems)
    ) {
      actions.fetchNowPlayingItems(currentlyPlayingGuideId);
    }

    this.scrollTimeout = setTimeout(this.startScrolling, 2000);
  }

  componentDidUpdate(prevProps) {
    const { nowPlaying, playerStatus } = this.props;

    const previousSubtitle = getSubtitle(prevProps?.nowPlaying);
    const newSubtitle = getSubtitle(nowPlaying);
    const didPlayerTransitionToFailedState =
      prevProps.playerStatus !== playerStatus &&
      playerStatus === playerStatuses.failed;

    if (previousSubtitle !== newSubtitle || didPlayerTransitionToFailedState) {
      // using setTimeout, as the refs aren't necessarily updating immediately
      setTimeout(this.startScrolling, 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  handleClose() {
    this.props.actions.closeNowPlayingDialogAndTrack();
  }

  startScrolling() {
    const titleInnerWidth = get(
      this.titleInnerContainer,
      'current.scrollWidth',
    );
    const titleOuterWidth = get(
      this.titleOuterContainer,
      'current.clientWidth',
    );
    const subtitleInnerWidth = get(
      this.subtitleInnerContainer,
      'current.scrollWidth',
    );
    const subtitleOuterWidth = get(
      this.subtitleOuterContainer,
      'current.clientWidth',
    );

    this.setState({
      isTitleScrolling: titleInnerWidth > titleOuterWidth,
      isSubtitleScrolling: subtitleInnerWidth > subtitleOuterWidth,
    });
  }

  processTitle() {
    const { nowPlaying, isMediaAdLoaded } = this.props;
    const { getLocalizedText } = this.context;

    const titleTextCss = this.state.isTitleScrolling ? css.scrolling : '';
    // NOTE: Render subtitle (e.g. Radiohead - Fake Plastic Trees) below image
    // TODO: Fix naming of these title and subtitle functions to properly reflect their usage
    const title = getSubtitle(nowPlaying);
    const titleText = isMediaAdLoaded
      ? getLocalizedText(MEDIA_AD_TITLE)
      : title;
    const testId = isMediaAdLoaded ? 'MediaAdTitle' : 'Title';

    return {
      testId,
      titleText,
      titleTextCss,
    };
  }

  processSubtitle() {
    const { nowPlaying, playerStatus, isMediaAdLoaded } = this.props;
    const { getLocalizedText } = this.context;

    // NOTE: Render title (e.g. ALT 92.9) in page header
    // TODO: Fix naming of these title and subtitle functions to properly reflect their usage
    const subtitle =
      playerStatus === playerStatuses.failed
        ? getLocalizedText(ERROR_MESSAGE)
        : getTitle(nowPlaying, getLocalizedText);
    const subtitleTextCss = this.state.isSubtitleScrolling ? css.scrolling : '';

    const subtitleText = isMediaAdLoaded
      ? getLocalizedText(MEDIA_AD_SUBTITLE)
      : subtitle;
    const testId = isMediaAdLoaded ? 'MediaAdSubtitle' : 'Subtitle';

    return {
      testId,
      subtitleText,
      subtitleTextCss,
    };
  }

  handleSeek = (secondsToSeek) => {
    const { actions, duration, elapsedSeconds } = this.props;
    const positionPercent = ((elapsedSeconds + secondsToSeek) / duration) * 100;

    actions.seek(positionPercent > 0 ? positionPercent : 0);
  };

  renderDesktopNowPlaying() {
    const { nowPlaying } = this.props;

    return (
      <>
        <div className={css.topSection}>
          <div className={css.actionsAndTitle} data-testid="actionsAndTitle">
            <div
              onClick={this.handleClose}
              data-testid={`${idPrefix}MinimizeContainer`}
              className={css.minimizeContainer}
            >
              <LegacyCaretDown
                key={`${idPrefix}Minimize`}
                className={css.minimizeButton}
                fill="white"
              />
            </div>
          </div>
        </div>
        <div className={css.innerDesktopContent}>
          <div className={css.flexContainer}>
            <ImageWithDefault
              id={`${idPrefix}Artwork`}
              containerClassName={css.artworkImageContainer}
              className={css.artworkImage}
              src={nowPlaying.image}
              alt={nowPlaying.title}
            />
            <div className={css.underImageTextContainer}>
              {this.renderDesktopTitle()}
              {this.renderDesktopSubtitle()}
            </div>
          </div>
        </div>
      </>
    );
  }

  renderDefaultNowPlaying() {
    const {
      nowPlaying,
      breakpoint,
      actions,
      matchUrl,
      selectedContentId,
      upsell,
      canShowUpsell,
      isUserSubscribed,
      canChangePlaybackRate,
      hideDisplayAd,
      isExpiredWebSubscriber,
      isMobile,
      isDiscord,
      canScrub,
      [ENABLE_PLAYBACK_SPEED_CONTROL]: isPlaybackRateExperimentEnabled,
      isVideoAdDialogOpen,
      nowPlayingRejectReasonKey,
    } = this.props;
    const { location, getLocalizedText } = this.context;

    const playButtonSize = is360(breakpoint) ? 80 : 60;
    const isDiscordMobile = isDiscord && isMobile;
    const shouldShowNewControls = isDiscordMobile && !nowPlayingRejectReasonKey;
    const shouldShowSpeedControl =
      isPlaybackRateExperimentEnabled &&
      canChangePlaybackRate &&
      !isDiscordMobile;

    return (
      <>
        <div className={css.topSection}>
          <div
            className={classNames(css.actionsAndTitle, {
              [css.spaceBelowTitle]: !canShowUpsell,
              [css.shouldExpandSection]: isDiscord && !isMobile, // For the purpose of centering NP Image Content
            })}
            data-testid="actionsAndTitle"
          >
            <div
              onClick={this.handleClose}
              data-testid={`${idPrefix}MinimizeContainer`}
              className={css.minimizeContainer}
            >
              <LegacyCaretDown
                key={`${idPrefix}Minimize`}
                className={css.minimizeButton}
                fill="white"
              />
            </div>
            {(!isDiscord || isMobile) && this.renderDefaultTitle()}
            {isMobile && (
              <div className={css.actionsContainer}>
                <VolumeControl
                  iconContainerClass={css.actionDrawerIconContainer}
                  volumeContainerClass={css.volumeContainer}
                  isDisabled={isVideoAdDialogOpen}
                  fill="#ffffff"
                />
                <FavoriteControl
                  fill="#ffffff"
                  iconClassName={css.heartIcon}
                  unFavoritedClassName={css.unfavorited}
                />
              </div>
            )}
          </div>
          {canShowUpsell && (
            <UpsellRibbon
              onButtonClick={actions.closeNowPlayingDialog}
              buttonUrl={createSubscribeLink({
                guideId: selectedContentId,
                source: SOURCE,
                location,
              })}
              className={css.upsellRibbon}
              upsell={upsell}
              isExpiredWebSubscriber={isExpiredWebSubscriber}
            />
          )}
        </div>
        {!isUserSubscribed && !hideDisplayAd && (
          <NowPlayingDialogAd matchUrl={matchUrl} guideId={selectedContentId} />
        )}
        <ImageWithDefault
          id={`${idPrefix}Artwork`}
          containerClassName={classNames(css.artworkImageContainer, {
            [css.smallerImage]: canShowUpsell,
          })}
          className={css.artworkImage}
          src={nowPlaying.image}
          alt={nowPlaying.title}
        />
        {this.renderDefaultSubtitle()}
        {isDiscord && !isMobile && (
          <div className={css.bottomSection}>
            {!canScrub && (
              <div className={css.liveBug}>
                <span>{getLocalizedText(LIVE).toUpperCase()}</span>
              </div>
            )}
          </div>
        )}
        {(!isDiscord || isMobile) && (
          <>
            <div className={css.scrubberContainer}>
              <Scrubber isInNowPlayingDialog />
            </div>
            {shouldShowNewControls && (
              <div className={css.controlPillContainer}>
                <div
                  className={classNames(css.controlPill, {
                    [css.speedControlPill]: canScrub,
                  })}
                >
                  {canScrub ? (
                    <SpeedControl iconContainerClass={css.speedControlIcon} />
                  ) : (
                    <span>{getLocalizedText(LIVE).toUpperCase()}</span>
                  )}
                </div>
              </div>
            )}
            <div
              className={classNames(css.controlsContainer, {
                [css.newControls]: shouldShowNewControls,
              })}
            >
              {shouldShowSpeedControl && (
                <SpeedControl iconContainerClass={css.speedControlContainer} />
              )}
              {shouldShowNewControls && canScrub && (
                <RewindFifteenIcon
                  width="40px"
                  height="40px"
                  onClick={() => this.handleSeek(-15)}
                />
              )}
              <PlayButton
                className={classNames(css.playButtonContainer, {
                  [css.withSpeedControl]: shouldShowSpeedControl,
                })}
                customHeight={playButtonSize}
                customWidth={playButtonSize}
                breakpoint={breakpoint}
                customFill={cssVars['--secondary-color-7']}
                progressColor="white"
                enableDiscordControlTooltip
              />
              {shouldShowNewControls && canScrub && (
                <FastForwardFifteenIcon
                  width="40px"
                  height="40px"
                  onClick={() => this.handleSeek(15)}
                />
              )}
            </div>
          </>
        )}
      </>
    );
  }

  renderDefaultTitle() {
    const { testId, titleText, titleTextCss } = this.processTitle();

    return (
      <div ref={this.titleOuterContainer} className={css.title}>
        <div className={css.leftGradient} />
        <div
          ref={this.titleInnerContainer}
          className={titleTextCss}
          data-testid={idPrefix + testId}
        >
          {titleText}
        </div>
        <div className={css.rightGradient} />
      </div>
    );
  }

  renderDesktopTitle() {
    const { testId, titleText, titleTextCss } = this.processTitle();

    return (
      <div ref={this.titleOuterContainer} className={css.title}>
        <ClampText
          clamp={1}
          ref={this.titleInnerContainer}
          className={titleTextCss}
          data-testid={idPrefix + testId}
          truncationHTML="<span>...</span>"
        >
          {titleText}
        </ClampText>
      </div>
    );
  }

  renderDefaultSubtitle() {
    const { isDiscord, isMobile, guideItemPathname } = this.props;
    const { testId, subtitleText, subtitleTextCss } = this.processSubtitle();
    const { titleText } = this.processTitle();

    return (
      <>
        <div
          className={css.subtitle}
          ref={this.subtitleOuterContainer}
          data-testid={`${idPrefix}SubtitleContainer`}
        >
          <div className={css.leftGradient} />
          <div
            className={subtitleTextCss}
            ref={this.subtitleInnerContainer}
            data-testid={idPrefix + testId}
          >
            {subtitleText}
          </div>
          <div className={css.rightGradient} />
        </div>
        {isDiscord && !isMobile && (
          <Link className={css.subtitleProfileLink} to={guideItemPathname}>
            <span>{titleText}</span>
            <ChevronRight width="10px" height="18px" />
          </Link>
        )}
      </>
    );
  }

  renderDesktopSubtitle() {
    const { testId, subtitleText, subtitleTextCss } = this.processSubtitle();

    return (
      <div
        className={css.subtitle}
        ref={this.subtitleOuterContainer}
        data-testid={`${idPrefix}SubtitleContainer`}
      >
        <ClampText
          clamp={1}
          className={subtitleTextCss}
          ref={this.subtitleInnerContainer}
          data-testid={idPrefix + testId}
          truncationHTML="<span>...</span>"
        >
          {subtitleText}
        </ClampText>
      </div>
    );
  }

  render() {
    const { isDesktopNowPlayingEnabled } = this.props;

    return isDesktopNowPlayingEnabled
      ? this.renderDesktopNowPlaying()
      : this.renderDefaultNowPlaying();
  }
}

// exported for unit testing
export function mapStateToProps(state) {
  const upsell = selectNowPlayingUpsell(state);
  const isDiscord = selectIsDiscord(state);
  const { duration, elapsedSeconds = 0 } = selectPositionInfo(state);
  const parentGuideId = selectParentGuideId(state);
  const tunedGuideId = selectTunedGuideId(state);
  const primaryGuideId = parentGuideId || tunedGuideId;
  const guideItemPathname =
    selectGuideItemPathname(state) ||
    (primaryGuideId ? `/radio/${primaryGuideId}/` : '');

  return {
    duration,
    elapsedSeconds,
    userAgent: selectUserAgent(state),
    nowPlaying: selectNowPlaying(state),
    nowPlayingGuideItem: selectNowPlayingGuideItem(state),
    guideItemPathname,
    playerStatus: selectPlayerStatus(state),
    isMediaAdLoaded: selectIsMediaAdLoaded(state),
    upsell,
    canShowUpsell: !!(
      upsell.canUpsell &&
      !selectIsUserSubscribed(state) &&
      !isDiscord
    ),
    isUserSubscribed: selectIsUserSubscribed(state),
    canChangePlaybackRate: selectCanChangePlaybackRate(state),
    currentlyPlayingGuideId: tunedGuideId,
    isExpiredWebSubscriber: selectIsExpiredWebSubscriber(state),
    isDesktopNowPlayingEnabled: selectIsDesktopNowPlayingEnabled(state),
    isVideoAdDialogOpen: selectIsVideoAdDialogOpen(state),
    isDiscord,
    isMobile: selectIsMobile(state),
    canScrub: selectCanScrub(state),
    nowPlayingRejectReasonKey: selectNowPlayingRejectReasonKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeNowPlayingDialogAndTrack,
        closeNowPlayingDialog,
        fetchNowPlayingItems,
        seek,
      },
      dispatch,
    ),
  };
}

export default flow(
  connectWithAuth,
  connectWithExperiments([ENABLE_PLAYBACK_SPEED_CONTROL]),
  connect(mapStateToProps, mapDispatchToProps),
)(NowPlaying);
