import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import EventListener, { withOptions } from 'react-event-listener';
import { logCategoryActionLabel } from '../../actions/logging';
import useActions from '../../hooks/useActions';
import useOnMount from '../../hooks/useOnMount';
import { isElementInView } from './utils';

const TIMEOUT = 100;

export default function withGuideItemTracking(ComponentToDecorate) {
  const WithGuideItemTracking = (props) => {
    const { guideItem } = props;
    const ref = useRef();

    const [hasBeenReported, setHasBeenReported] = useState(false);
    const [isDisplayed, setIsDisplayed] = useState(true);

    const actions = useActions({ logCategoryActionLabel });

    const reportIfInView = useMemo(
      () =>
        debounce(() => {
          if (!hasBeenReported && isElementInView(ref.current)) {
            const itemToken = get(guideItem, 'context.token');
            const reportData = get(guideItem, 'actions.view.reportData', '');
            actions.logCategoryActionLabel(reportData, { itemToken });
            setHasBeenReported(true);
          } else if (hasBeenReported && !isElementInView(ref.current)) {
            setHasBeenReported(false);
          }
        }, TIMEOUT),
      [hasBeenReported, guideItem, actions, setHasBeenReported],
    );

    useOnMount(() => {
      reportIfInView();
    });

    function closeGuideItem() {
      setIsDisplayed(false);
    }

    return (
      isDisplayed && (
        <EventListener
          target="window"
          onScroll={withOptions(reportIfInView, {
            passive: false,
            capture: true,
          })}
        >
          <ComponentToDecorate
            trackingRef={ref}
            closeGuideItem={closeGuideItem}
            {...props}
          />
        </EventListener>
      )
    );
  };

  WithGuideItemTracking.propTypes = {
    guideItem: PropTypes.object.isRequired,
  };

  return WithGuideItemTracking;
}
