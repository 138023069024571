import assetUrl from '../assetUrl';

export default function getPlayedProgressSvgPath(playedPercentage, isDarkMode) {
  let filename = 'play-button-0-v3.svg';
  let status = 'incomplete';

  if (playedPercentage > 0 && playedPercentage < 25) {
    filename = 'play-button-25-v3.svg';
  } else if (playedPercentage >= 25 && playedPercentage < 50) {
    filename = 'play-button-50-v3.svg';
  } else if (playedPercentage >= 50 && playedPercentage < 75) {
    filename = 'play-button-75-v3.svg';
  } else if (playedPercentage >= 75 && playedPercentage < 100) {
    filename = 'play-button-97-v3.svg';
  } else if (playedPercentage >= 100) {
    filename = 'play-button-100-v3.svg';
    status = 'complete';
  }

  return {
    src: assetUrl(
      `assets/img/playProgress/${isDarkMode ? 'dark-' : ''}${filename}`,
    ),
    status,
  };
}
