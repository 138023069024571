import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { UPSELL_GO_PREMIUM } from 'src/common/constants/localizations/upsell';
import {
  UPSELL_BANNER_BUTTON_BG_COLOR,
  UPSELL_BANNER_BUTTON_TEXT_COLOR,
} from '../../constants/experiments/upsell';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import cssVars from '../../styles/variables';
import PillButtonLink from '../shared/button/PillButtonLink';
import css from './upsell-ribbon.module.scss';

// exported for testing
export class UpsellRibbon extends Component {
  static propTypes = {
    upsell: PropTypes.object.isRequired,
    isExpiredWebSubscriber: PropTypes.bool.isRequired,
    buttonUrl: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.object.isRequired,
    ]),
    onButtonClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    [UPSELL_BANNER_BUTTON_BG_COLOR]: PropTypes.string,
    [UPSELL_BANNER_BUTTON_TEXT_COLOR]: PropTypes.string,
  };

  static defaultProps = {
    upsell: {},
  };

  static contextType = LocationAndLocalizationContext;

  render() {
    const {
      upsell,
      onButtonClick,
      buttonUrl,
      className,
      [UPSELL_BANNER_BUTTON_BG_COLOR]: buttonBgColor,
      [UPSELL_BANNER_BUTTON_TEXT_COLOR]: buttonTextColor,
      isExpiredWebSubscriber,
    } = this.props;
    const { overlayText, text } = upsell;
    const { getLocalizedText } = this.context;

    const localizedPremiumText = isExpiredWebSubscriber
      ? getLocalizedText(UPSELL_GO_PREMIUM)
      : '';

    return (
      <div className={classNames(css.upsellRibbon, className)}>
        {(overlayText || isExpiredWebSubscriber) && (
          <span className={css.overlayText} data-testid="upsellBannerText">
            {localizedPremiumText || overlayText}
          </span>
        )}
        {buttonUrl && (text || isExpiredWebSubscriber) && (
          <PillButtonLink
            id="upsellRibbonButton"
            label={localizedPremiumText || text}
            to={buttonUrl}
            onClick={onButtonClick}
            className={css.button}
            backgroundColor={buttonBgColor || cssVars['--white']}
            textColor={buttonTextColor || cssVars['--secondary-color-5']}
          />
        )}
      </div>
    );
  }
}

export default connectWithExperiments([
  UPSELL_BANNER_BUTTON_BG_COLOR,
  UPSELL_BANNER_BUTTON_TEXT_COLOR,
])(UpsellRibbon);
