import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { selectIsMobile } from 'src/common/selectors/app';
import { selectIsNowPlayingDialogOpen } from 'src/common/selectors/dialog';
import { GO_TO_PROFILE_LABEL } from '../../../constants/localizations/button';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import ClampText from '../../shared/ClampText';
import GuideItemLink from '../shared/GuideItemLink';
import Titles from '../shared/Titles';
import css from './item-card.module.scss';

// exported for testing
export const ItemCard = (props) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const { guideItem = {}, index, isMobile, isNowPlayingDialogOpen } = props;

  if (!isMobile || !isNowPlayingDialogOpen) {
    return null;
  }

  const { title, subtitle, description, guideId } = guideItem;

  return (
    <div
      className={css.itemCardContainer}
      data-testid={`guideItem-${index}`}
      data-testtype="itemCard"
      data-nextguideitem={guideId}
    >
      <div className={css.topContainer}>
        <Titles
          title={title}
          subtitle={subtitle}
          titleMaxLineCount={2}
          subtitleMaxLineCount={1}
          titleClassName={css.title}
          subtitleClassName={css.subtitle}
        />
      </div>
      {description && (
        <ClampText clamp={3} className={css.description}>
          {description}
        </ClampText>
      )}
      <GuideItemLink className={css.profileLink} guideItem={guideItem}>
        {getLocalizedText(GO_TO_PROFILE_LABEL)}
      </GuideItemLink>
    </div>
  );
};

ItemCard.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isNowPlayingDialogOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isMobile: selectIsMobile(state),
    isNowPlayingDialogOpen: selectIsNowPlayingDialogOpen(state),
  };
}

export default connect(mapStateToProps)(ItemCard);
