import PropTypes from 'prop-types';
import useExperiment from 'src/common/hooks/useExperiment';
import { WEP_PROMPT_HEIGHT_OVERRIDE } from '../../../../constants/experiments/config';

const SANDBOX_RULES =
  'allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox';

export default function WebPrompt({ guideItem }) {
  const heightOverride = useExperiment(WEP_PROMPT_HEIGHT_OVERRIDE);
  const { height, url } = guideItem?.presentation || {};
  const heightToUse = heightOverride || Number.parseInt(height);
  if (!url || !heightToUse) {
    return null;
  }
  return (
    <iframe
      data-testid="webPrompt"
      height={heightToUse}
      width="100%"
      src={url}
      sandbox={SANDBOX_RULES}
      scrolling="no"
    />
  );
}

WebPrompt.propTypes = {
  guideItem: PropTypes.object.isRequired,
};
