import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  SEE_LESS,
  SEE_MORE,
} from '../../../constants/localizations/containerItems';
import useToggleState from '../../../hooks/useToggleState';
import { selectIsDiscord, selectIsMobile } from '../../../selectors/app';
import { selectIsNowPlayingDialogOpen } from '../../../selectors/dialog';
import ClampText from '../../shared/ClampText';
import CollapsibleLabel from '../../shared/CollapsibleLabel';
import sharedCss from '../shared/shared-card.module.scss';
import css from './episode-card.module.scss';

const EpisodeCard = ({ guideItem = {}, index }) => {
  const isDiscord = useSelector(selectIsDiscord);
  const [shouldShowMore, handleMoreClick] = useToggleState();
  const isMobile = useSelector(selectIsMobile);
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);

  if (!isMobile || !isNowPlayingDialogOpen) {
    return null;
  }

  const { title, subtitle, description, guideId, properties } = guideItem;

  const duration = get(properties, 'playbackStatus.duration');
  const publishDate = get(properties, 'publishDate.formattedLocalizedDate');

  return (
    <div
      className={css.episodeCardContainer}
      data-testid={`guideItem-${index}`}
      data-testtype="episodeCard"
      data-nextguideitem={guideId}
    >
      {title && <div className={css.title}>{title}</div>}
      {subtitle && <div className={css.subtitle}>{subtitle}</div>}
      {description && (
        <>
          <ClampText
            clamp={shouldShowMore ? false : 6}
            className={css.description}
          >
            {description}
          </ClampText>
          {shouldShowMore && (publishDate || duration) && (
            <div className={css.dateAndDurationContainer}>
              {publishDate && (
                <div data-testid="publishDate">{publishDate}</div>
              )}
              {duration && <div data-testid="duration">{duration}</div>}
            </div>
          )}
          <CollapsibleLabel
            onMoreInfoClick={handleMoreClick}
            shouldShowMore={shouldShowMore}
            moreText={SEE_MORE}
            lessText={SEE_LESS}
            className={classNames(sharedCss.collapsibleLabel, {
              [sharedCss.isDiscord]: isDiscord,
            })}
            caretClassName={sharedCss.caret}
          />
        </>
      )}
    </div>
  );
};

EpisodeCard.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default EpisodeCard;
