const actions = {
  deeplink: 'deeplink',
};

const labels = {};

const viewModel = {
  category: 'viewModelAction',
  actions,
  labels,
};

export default viewModel;
