/* eslint-disable max-len */
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';

/**
 * This is effectively a React component that surrounds the existing
 * `src/assets/img/shared/caret-down-v2.svg` asset. This React component is a special case one, which
 * we can use to render inline svg. However, for most cases, we should use the `caret-down-v2.svg`
 * asset. In other cases, such as showing the caret-down-v2 svg in the mobile upsell, rendering this
 * inline svg is preferable.
 */
const LegacyCaretDown = ({ className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="10px"
    viewBox="6 6 12 14"
    fill={cssVariables['--secondary-color-5']}
    className={className}
    style={style}
  >
    <path d="M21.55,9.11,19.42,7a.48.48,0,0,0-.7,0L12,13.71,5.28,7a.48.48,0,0,0-.7,0L2.45,9.11a.51.51,0,0,0,0,.71L11.65,19a.48.48,0,0,0,.7,0l9.2-9.19A.51.51,0,0,0,21.55,9.11Z" />
  </svg>
);

LegacyCaretDown.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LegacyCaretDown;
