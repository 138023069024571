// keys are proper case because they could be referencing a value from api response

export const imageAlignments = {
  Left: 'Left',
  Right: 'Right',
};

export const imageSizes = {
  Small: 'Small',
  Large: 'Large',
  XXLarge: 'XXLarge',
};

export const textWidths = {
  Medium: 'Medium',
};

export const textStyles = {
  Underline: 'Underline',
};

export const buttonStyles = {
  Rounded: 'Rounded',
};

export const marginStyles = {
  None: 'None',
};
