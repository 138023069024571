import get from 'lodash/get';
import PropTypes from 'prop-types';
import Prompt from './Prompt';
import SuperPrompt from './SuperPrompt';

export default function PromptSelector(props) {
  const { guideItem } = props;
  const shouldUseSuperPrompt = get(guideItem, 'presentation.isCompactMode');
  const PromptToRender = shouldUseSuperPrompt ? SuperPrompt : Prompt;

  return <PromptToRender {...props} />;
}

// these may be required in one of the prompts and not the other
// see individual prompts for their required PropTypes
PromptSelector.propTypes = {
  guideItem: PropTypes.object,
  containerItem: PropTypes.object,
  promptActions: PropTypes.object,
  getStyles: PropTypes.func,
};
