import classNames from 'clsx';
import compact from 'lodash/compact';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  logCategoryActionLabel,
  logClientInfo,
  logInterestSelectorAction,
  logWebActivity,
} from 'src/common/actions/logging';
import {
  reportContentClickedEvent,
  setBreadcrumbId,
} from 'src/common/actions/reporting';
import { selectIsUnifiedEventsContentReportingEnabled } from 'src/common/selectors/config';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import cssVariables from 'src/common/styles/variables';
import { validateAndGetHexColor } from 'src/common/utils/color';
import { discordFriendlyExternalLinking } from 'src/common/utils/discord';
import { doesContainerSupportContentReporting } from 'src/common/utils/guideItem/doesContainerSupportContentReporting';
import { needsSignInDialog, needsSignUpDialog } from '../../../actions/auth';
import {
  closeNowPlayingDialog,
  fetchItemsAndOpenIntSelDialog,
  openUpsellAndTrackActivity,
} from '../../../actions/dialog';
import { updateInterestSelectorItems } from '../../../actions/favoriteInterests';
import feature from '../../../constants/analytics/categoryActionLabel/feature';
import subscribe from '../../../constants/analytics/categoryActionLabel/subscribe';
import viewModel from '../../../constants/analytics/categoryActionLabel/viewModel';
import web from '../../../constants/analytics/categoryActionLabel/web';
import { desktopFuncNames } from '../../../constants/desktop';
import { WEB_ACTION_INTEREST_DIALOG_URL } from '../../../constants/dialogs/interestSelector';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../../../constants/experiments/dialog';
import { UPSELL_CTA_TEXT } from '../../../constants/experiments/upsell';
import { buttonStyles, textStyles } from '../../../constants/styles';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectIsDiscord } from '../../../selectors/app';
import { selectExperiment } from '../../../selectors/config';
import { selectIsNowPlayingDialogOpen } from '../../../selectors/dialog';
import { selectIsUserSubscribed } from '../../../selectors/me';
import invokeDesktopFunction from '../../../utils/desktop/invokeDesktopFunction';
import isDesktop from '../../../utils/desktop/isDesktop';
import { getDestInfoUrlObj } from '../../../utils/guideItem/getGuideItemPathname';
import getGuideItemProducts from '../../../utils/guideItem/getGuideItemProducts';
import { behaviors } from '../../../utils/guideItemTypes';
import createSubscribeLink from '../../../utils/subscription/createSubscribeLink';
import { urlStringToComponents } from '../../../utils/urlHelpers';
import createAndOpenSubscribeLink from '../../utils/createAndOpenSubscribeLink';
import { parseSuccessDeeplink } from '../../utils/interestSelector';
import css from './action-button.module.scss';

const textCss = {
  [textStyles.Underline]: css.underlineText,
};

const buttonShapeStyles = {
  [buttonStyles.Rounded]: css.roundedButton,
};

const actionButtonLogging = {
  invalid: 'invalidButtonAction',
  unsupported: 'unsupportedButtonAction',
  exception: 'actionButtonException',
};

export class ActionButton extends Component {
  static propTypes = {
    buttonMetadata: PropTypes.object,
    buttonActions: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    guideItem: PropTypes.object,
    source: PropTypes.string,
    actionLabelPrefix: PropTypes.string.isRequired,
    getStyles: PropTypes.func.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    isNowPlayingOpen: PropTypes.bool.isRequired,
    interestSelectorDialogUrl: PropTypes.string,
    upsellCtaText: PropTypes.string,
    isUserSubscribed: PropTypes.bool.isRequired,
    isPremiumUpsellBypassEnabled: PropTypes.bool.isRequired,
    isUnifiedEventsContentReportingEnabled: PropTypes.bool.isRequired,
    breadcrumbId: PropTypes.string.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  static defaultProps = {
    actionLabelPrefix: '',
    buttonMetadata: {},
  };

  state = {
    isButtonDisabled: false,
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  reportContentClickedEvent() {
    const {
      actions,
      guideItem,
      isUnifiedEventsContentReportingEnabled,
      breadcrumbId,
    } = this.props;

    if (!isUnifiedEventsContentReportingEnabled) {
      return;
    }

    if (!breadcrumbId) {
      actions.setBreadcrumbId();
    }

    if (
      doesContainerSupportContentReporting(guideItem.reporting?.container?.type)
    ) {
      actions.reportContentClickedEvent(guideItem);
    }
  }

  handleSubscribe() {
    const {
      actions,
      guideItem,
      source,
      actionLabelPrefix,
      history,
      isNowPlayingOpen,
    } = this.props;
    const { location } = this.context;

    const { buttonActions } = this.props;
    const additionalParams = {
      sku: compact(getGuideItemProducts({ actions: buttonActions })),
    };
    const subscribeLink = createSubscribeLink({
      source,
      guideId: guideItem?.guideId,
      location,
      additionalParams,
    });

    actions.logWebActivity(
      web.actions.tap,
      `${actionLabelPrefix}-${behaviors.subscribe}`,
    );
    if (isNowPlayingOpen) {
      actions.closeNowPlayingDialog();
    }
    history.push(subscribeLink);
  }

  handleBrowseProfileNavigation() {
    const {
      history,
      actions,
      actionLabelPrefix,
      buttonMetadata,
      isNowPlayingOpen,
    } = this.props;
    const destinationInfo = buttonMetadata?.destinationInfo || {};
    const browseUrlObj = getDestInfoUrlObj(destinationInfo);
    if (!browseUrlObj) {
      actions.logClientInfo({
        message: actionButtonLogging.invalid,
        context: { button: buttonMetadata },
      });
      return;
    }
    actions.logWebActivity(
      web.actions.tap,
      `${actionLabelPrefix}-${behaviors.browse}-${behaviors.profile}`,
      destinationInfo.id,
    );
    if (isNowPlayingOpen) {
      actions.closeNowPlayingDialog();
    }
    history.push(browseUrlObj);
  }

  openExternalLink() {
    const { actions, actionLabelPrefix, buttonMetadata, isDiscord } =
      this.props;
    const buttonUrl = buttonMetadata?.data;
    actions.logWebActivity(
      web.actions.tap,
      `${actionLabelPrefix}-${behaviors.link}`,
      buttonUrl,
    );

    if (isDesktop()) {
      invokeDesktopFunction(desktopFuncNames.openSocialShare, buttonUrl);
      return;
    }

    if (isDiscord) {
      discordFriendlyExternalLinking(buttonUrl);
      return;
    }

    window.open(buttonUrl);
  }

  handleLinkAction() {
    const { buttonMetadata, actions, interestSelectorDialogUrl } = this.props;

    const internalActions = {
      [interestSelectorDialogUrl]: actions.fetchItemsAndOpenIntSelDialog,
    };

    const buttonUrl = buttonMetadata?.data || '';
    const { urlWithoutQueryParams, queryParams, queryString, pathname } =
      urlStringToComponents(buttonUrl);
    const actionsKey = urlWithoutQueryParams?.endsWith('/')
      ? urlWithoutQueryParams.slice(0, -1)
      : urlWithoutQueryParams;
    const internalAction = internalActions[actionsKey];

    if (internalAction) {
      internalAction(queryParams);
      actions.logCategoryActionLabel({
        category: viewModel.category,
        action: viewModel.actions.deeplink,
        label: `${pathname.slice(1, -1)}${queryString}`, // slicing leading forward slash from pathname
      });
    } else {
      this.openExternalLink();
    }
  }

  async handleInterest() {
    const {
      actions,
      buttonMetadata = {},
      guideItem,
      isUserSubscribed,
      isPremiumUpsellBypassEnabled,
    } = this.props;
    const { location } = this.context;
    const { data, successDeeplink } = buttonMetadata;

    await actions.updateInterestSelectorItems(data, feature.labels.oneClick);

    if (!successDeeplink.length || isUserSubscribed) {
      return;
    }

    if (isPremiumUpsellBypassEnabled) {
      createAndOpenSubscribeLink(guideItem, location);
      return;
    }

    try {
      const { upsellLabelSuffix } = parseSuccessDeeplink(successDeeplink);
      const upsellLabel = upsellLabelSuffix
        ? `${subscribe.labels.interestSelectorUpsell}.${upsellLabelSuffix}`
        : subscribe.labels.interestSelectorUpsell;

      actions.openUpsellAndTrackActivity(upsellLabel);
    } catch (errorLabel) {
      actions.logInterestSelectorAction(
        feature.labels.error,
        feature.labels.oneClick,
        errorLabel,
      );
    }
  }

  async handleClick() {
    if (this.state.isButtonDisabled) {
      return;
    }

    const { buttonMetadata, onClose, actions, actionLabelPrefix } = this.props;
    const buttonAction = buttonMetadata?.destination;
    this.setState({ isButtonDisabled: true });

    this.reportContentClickedEvent();

    try {
      // idea here is that we add more actions to this in the future
      switch (buttonAction) {
        case behaviors.dismiss: {
          onClose(`${actionLabelPrefix}-${behaviors.dismiss}`);
          break;
        }
        case behaviors.browse:
        case behaviors.profile: {
          this.handleBrowseProfileNavigation();
          break;
        }
        case behaviors.subscribe: {
          this.handleSubscribe();
          break;
        }
        case behaviors.signIn: {
          actions.openSignInDialog();
          break;
        }
        case behaviors.signUp: {
          actions.openSignUpDialog();
          break;
        }
        case behaviors.link: {
          this.handleLinkAction();
          break;
        }
        case behaviors.interest: {
          await this.handleInterest();
          break;
        }
        default: {
          // logging this for now until we add support for other types
          actions.logClientInfo({
            message: actionButtonLogging.unsupported,
            context: { buttonAction },
          });
        }
      }
    } catch (ex) {
      actions.logClientInfo({
        message: actionButtonLogging.exception,
        context: { buttonAction, message: ex?.message },
      });
    }

    this.setState({ isButtonDisabled: false });
  }

  render() {
    const { buttonMetadata, getStyles, upsellCtaText } = this.props;
    const { getLocalizedText } = this.context;
    const { isButtonDisabled } = this.state;

    const {
      shape,
      backgroundColor: bgColor,
      textColor,
      textStyle,
    } = getStyles(buttonMetadata?.style);

    const backgroundColorToUse = validateAndGetHexColor(bgColor, 'transparent');
    const textColorToUse = validateAndGetHexColor(
      textColor,
      cssVariables['--secondary-color-5'],
    );
    const buttonAction = buttonMetadata?.destination;

    const buttonTextStyle = {};
    if (textStyle === textStyles.Underline) {
      buttonTextStyle.borderColor = textColorToUse;
    }

    const buttonText =
      buttonAction === behaviors.subscribe && upsellCtaText
        ? getLocalizedText(upsellCtaText)
        : buttonMetadata?.text;

    return (
      <div
        className={classNames(css.actionButton, buttonShapeStyles[shape], {
          [css.disabled]: isButtonDisabled,
        })}
        onClick={this.handleClick}
        style={{ backgroundColor: backgroundColorToUse, color: textColorToUse }}
        data-testid={`actionButton-${buttonAction}`}
      >
        <div
          className={classNames(css.buttonText, textCss[textStyle])}
          style={buttonTextStyle}
          data-testid={`actionButtonText-${buttonAction}`}
        >
          {buttonText}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    logWebActivity,
    logClientInfo,
    logCategoryActionLabel,
    fetchItemsAndOpenIntSelDialog,
    closeNowPlayingDialog,
    openSignInDialog: needsSignInDialog,
    openSignUpDialog: needsSignUpDialog,
    updateInterestSelectorItems,
    logInterestSelectorAction,
    openUpsellAndTrackActivity,
    reportContentClickedEvent,
    setBreadcrumbId,
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    isDiscord: selectIsDiscord(state),
    isNowPlayingOpen: selectIsNowPlayingDialogOpen(state),
    interestSelectorDialogUrl: selectExperiment(
      state,
      WEB_ACTION_INTEREST_DIALOG_URL,
    ),
    upsellCtaText: selectExperiment(state, UPSELL_CTA_TEXT),
    isUserSubscribed: selectIsUserSubscribed(state),
    isPremiumUpsellBypassEnabled: selectExperiment(
      state,
      BYPASS_UPSELL_DIALOG_ENABLED,
      false,
    ),
    isUnifiedEventsContentReportingEnabled:
      selectIsUnifiedEventsContentReportingEnabled(state),
    breadcrumbId: selectBreadcrumbId(state),
  };
}

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ActionButton);
