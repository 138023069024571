import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import { selectIsDiscord } from 'src/common/selectors/app';
import css from './collapsible-label.module.scss';

const CollapsibleLabel = ({
  shouldShowMore,
  onMoreInfoClick,
  moreText,
  lessText,
  labelStyle,
  className,
  caretClassName,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const isDiscord = useSelector(selectIsDiscord);
  const buttonText = shouldShowMore ? lessText : moreText;

  return (
    <button
      style={labelStyle}
      className={classNames(css.moreButton, className)}
      onClick={onMoreInfoClick}
      data-testid="profileMoreInfo"
    >
      <span className={css.moreText}>{getLocalizedText(buttonText)}</span>
      <span
        className={classNames(css.caret, caretClassName, {
          [css.up]: shouldShowMore,
          [css.down]: !shouldShowMore,
          [css.darkMode]: isDiscord,
        })}
        data-testid={
          shouldShowMore ? 'moreInfoChevronUp' : 'moreInfoChevronDown'
        }
      />
    </button>
  );
};

CollapsibleLabel.propTypes = {
  shouldShowMore: PropTypes.bool.isRequired,
  onMoreInfoClick: PropTypes.func.isRequired,
  moreText: PropTypes.node.isRequired,
  lessText: PropTypes.node.isRequired,
  labelStyle: PropTypes.object,
  className: PropTypes.string,
  caretClassName: PropTypes.string,
};

export default CollapsibleLabel;
