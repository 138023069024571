import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DISCORD_PLAYBACK_DISABLED_TEXT } from 'src/common/components/player/actionDrawer/DiscordControls/constants';
import withMouseHover from 'src/common/decorators/hovers/withMouseHover';
import withSyncedPlayerBehavior from 'src/common/decorators/withSyncedPlayerBehavior';
import cssVariables from 'src/common/styles/variables';
import { playbackStatusIcons } from 'src/common/utils/playbackStatusIconMap';
import useMobilePopout from '../../../../../hooks/useMobilePopout';
import {
  selectDiscordState,
  selectIsMobile,
} from '../../../../../selectors/app';
import { getSelectPlaybackHistory } from '../../../../../selectors/playbackHistory';
import { selectNowPlayingRejectReasonKey } from '../../../../../selectors/player';
import isLocked from '../../../../../utils/guideItem/isLocked';
import MessagePopoverWithTail from '../../../../messagePopoverWithTail/MessagePopoverWithTail';
import GuideItemLink from '../../../shared/GuideItemLink';
import LockedTile from '../../../shared/LockedTile';
import css from '../../../shared/guide-item.module.scss';
import PlayButton from './PlayButton';
import playSectionCss from './play-section.module.scss';

/**
 * This component renders a detail section of that contains all the information
 * needed to play a station/podcast.  It includes the proper play button
 *
 * @param {object} guideItem - The guideItem that pertains to this particular station/podcast
 * @param {string} syncedPlayerStatus - The current status of the player
 * @param {boolean} isMediaAdLoaded - Whether or not the player has an ad currently loaded
 * @param {boolean} canScrub - Whether or not the stream can be scrubbed
 * @param {boolean} isHovering - Decides what color to display
 * @param {function} handleMouseEnter - Callback for mouseEnter event handler
 * @param {function} handleMouseLeave - Callback for mouseLeave event handler
 * @param {function} handlePlayButtonClick - Callback for play button click event handler
 * @param {string} playerStatus - The current status of the player
 * @param {string} externalHtmlPlayerUrl - The URL to the stream to be played in a popout
 * @param {string} tunedGuideId - Currently playing item in the player
 * @param {boolean} isDiscord - Whether the app is in Discord mode
 */
export const PlayableSection = ({
  guideItem,
  syncedPlayerStatus,
  isMediaAdLoaded,
  canScrub,
  isHovering,
  handleMouseEnter,
  handleMouseLeave,
  handlePlayButtonClick,
  playerStatus,
  externalHtmlPlayerUrl,
  tunedGuideId,
  isDiscord,
}) => {
  const playbackHistory = useSelector(
    getSelectPlaybackHistory(guideItem?.guideId),
  );
  const discordState = useSelector(selectDiscordState);
  const isMobile = useSelector(selectIsMobile);
  const { canShowPopover, handleTargetClick } = useMobilePopout(isMobile, 3000);
  const nowPlayingRejectReasonKey = useSelector(
    selectNowPlayingRejectReasonKey,
  );

  const hoverColor = isHovering
    ? cssVariables['--cloud-grey']
    : cssVariables[isDiscord ? '--anti-flash-white' : '--secondary-color-5'];
  const titleStyle = {
    color: hoverColor,
  };

  const playbackStatus = guideItem?.properties?.playbackStatus;
  const isNewPlaybackItem =
    playbackStatus &&
    playbackStatus.statusIcon === playbackStatusIcons.newPlaybackItem;
  const isPopoverOpen = Boolean(
    isDiscord &&
      (!discordState?.canControlPlayback ||
        (guideItem.guideId === tunedGuideId && nowPlayingRejectReasonKey)) &&
      ((isMobile && canShowPopover) || (!isMobile && isHovering)),
  );

  /**
   * Construct the child elements of the playable section with the appropriate callbacks
   */
  const children = (
    <div
      onClick={handleTargetClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={playSectionCss.playSectionLeftContent}>
        <div className={playSectionCss.playSectionButtonWrapper}>
          <PlayButton
            externalHtmlPlayerUrl={externalHtmlPlayerUrl}
            playerStatus={playerStatus}
            playbackStatus={playbackStatus}
            playbackHistory={
              playbackHistory || playbackStatus.progressPercent || 0
            }
            syncedPlayerStatus={syncedPlayerStatus}
            canScrub={canScrub}
            color={hoverColor}
            guideItem={guideItem}
            tunedGuideId={tunedGuideId}
            isMediaAdLoaded={isMediaAdLoaded}
            isDiscordPlayableSection={isDiscord}
          />
        </div>
      </div>
      <MessagePopoverWithTail
        message={
          guideItem.guideId === tunedGuideId
            ? nowPlayingRejectReasonKey || DISCORD_PLAYBACK_DISABLED_TEXT
            : DISCORD_PLAYBACK_DISABLED_TEXT
        }
        popoverClassName={playSectionCss.popover}
        isOpen={isPopoverOpen}
      />
      <div className={playSectionCss.playSectionMainContent}>
        <h2
          className={classNames(css.guideItemTitleSingleLine, {
            [css.isDiscordPlayableSection]: isDiscord,
          })}
          style={titleStyle}
          data-testid="statusTitle"
        >
          {guideItem.title}
          {isNewPlaybackItem && (
            <div
              data-testid="newPlayableItemIndicator"
              className={css.newPlayableItemIndicator}
            />
          )}
        </h2>
        {guideItem.subtitle && (
          <div
            className={classNames(css.guideItemSubtitle, {
              [css.isDiscordPlayableSection]: isDiscord,
            })}
            data-testid="statusSubtitle"
          >
            {guideItem.subtitle}
          </div>
        )}
      </div>
    </div>
  );

  /* If should show the upsell dialog */
  if (isLocked(guideItem)) {
    return (
      <LockedTile
        dataTestId="statusPlayerLockedAction"
        guideItem={guideItem}
        className={playSectionCss.playSectionContainer}
      >
        {children}
      </LockedTile>
    );
  }

  /**
   * Render the component
   */
  return (
    <GuideItemLink
      dataTestId="statusPlayerAction"
      className={playSectionCss.playSectionContainer}
      onClick={handlePlayButtonClick}
      guideItem={guideItem}
    >
      {children}
    </GuideItemLink>
  );
};

PlayableSection.propTypes = {
  guideItem: PropTypes.object.isRequired,
  syncedPlayerStatus: PropTypes.string.isRequired,
  isMediaAdLoaded: PropTypes.bool.isRequired,
  canScrub: PropTypes.bool,
  isHovering: PropTypes.bool.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  handlePlayButtonClick: PropTypes.func.isRequired,
  playerStatus: PropTypes.string,
  externalHtmlPlayerUrl: PropTypes.string,
  tunedGuideId: PropTypes.string,
  isDiscord: PropTypes.bool,
};

export default flow(withMouseHover, withSyncedPlayerBehavior)(PlayableSection);
