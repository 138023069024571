import PropTypes from 'prop-types';
import {
  DURATION,
  LESS,
  MORE,
} from 'src/common/constants/localizations/containerItems';
import { secondsToTimeWithHour } from 'src/common/utils/secondsToTime';
import Text from '../../../Text';
import CollapsibleItem from '../../../shared/CollapsibleItem';
import css from '../../shared/guide-item.module.scss';
import PlayableSection from './status/PlayableSection';

const styles = {
  container: {
    padding: '15px 0px',
  },
  moreLabel: {
    cursor: 'pointer',
  },
  mainContent: {
    marginLeft: 50,
    marginTop: 8,
  },
};

const Status = ({ guideItem, index, isDiscord }) => {
  const { duration } = guideItem.actions.play;
  const canExpand =
    guideItem.actions.expand && guideItem.actions.expand.canExpand;

  return (
    <div
      className="row"
      style={styles.container}
      data-testid={`guideItem-${index}`}
      data-testtype="status"
    >
      <div className="col-xs-12" data-id={`status-${guideItem.guideId}`}>
        <PlayableSection guideItem={guideItem} isDiscord={isDiscord} />
        {canExpand && (!!duration || guideItem.description) && (
          <div style={styles.mainContent} data-testid="statusMainContent">
            <CollapsibleItem
              moreText={MORE}
              lessText={LESS}
              className={css.statusFontSection}
              labelStyle={styles.moreLabel}
            >
              <div
                data-testid="description"
                className={css.guideItemDescription}
              >
                {guideItem.description}
                {!!duration && (
                  <div
                    data-testid="duration"
                    className={css.guideItemDescription}
                  >
                    <p className={css.guideItemDurationContainer}>
                      <Text name={DURATION} />
                      {secondsToTimeWithHour(duration)}
                    </p>
                  </div>
                )}
              </div>
            </CollapsibleItem>
          </div>
        )}
      </div>
    </div>
  );
};

Status.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isDiscord: PropTypes.bool,
};

export default Status;
