import { Component } from 'react';

export default function withCollapsibleState(MyComponent) {
  return class WithCollapsibleState extends Component {
    constructor(props) {
      super(props);

      this.state = {
        shouldShowMore: false,
      };

      this.handleMoreClick = this.handleMoreClick.bind(this);
    }

    handleMoreClick() {
      this.setState({
        shouldShowMore: !this.state.shouldShowMore,
      });
    }

    resetCollapsibleState = () => {
      this.setState({
        shouldShowMore: false,
      });
    };

    render() {
      return (
        <MyComponent
          {...this.props}
          handleMoreClick={this.handleMoreClick}
          shouldShowMore={this.state.shouldShowMore}
          resetCollapsibleState={this.resetCollapsibleState}
        />
      );
    }
  };
}
