import debounce from 'lodash/debounce';
import { useState } from 'react';
import useOnMount from './useOnMount';

export default function useStateWithDebounce(
  initialState,
  timeout,
  options = {},
) {
  const [state, setState] = useState(initialState);
  const [debouncedSetState] = useState(() =>
    debounce(setState, timeout, options),
  );

  useOnMount(() => () => debouncedSetState.cancel());

  return [state, debouncedSetState];
}
