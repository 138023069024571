import classNames from 'clsx';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { ReactSVG } from 'react-svg';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import cssVariables from 'src/common/styles/variables';
import getPlayedProgressSvgPath from 'src/common/utils/playerStatus/getPlayedProgressSvgPath';
import {
  playIconPlayerStatuses,
  playerStatusToSvgIconMap,
} from 'src/common/utils/playerStatusToIconMap';
import CircularProgress from '../../../../shared/CircularProgress';
import PopOutButton from '../../../../shared/button/PopOutButton';
import css from './play-button.module.scss';

/**
 * General styles for button
 */
const styles = {
  button: {
    height: '35px',
    width: '35px',
  },
};

/**
 * PlayButton for profile list item
 *
 * @param {string} syncedPlayerStatus - The current status of the player
 * @param {boolean} isMediaAdLoaded - Indicates whether the current stream is an ad
 * @param {boolean} canScrub - Indicates whether the stream can be scrubbed
 * @param {string} color - The color of the button
 * @param {string} playbackStatus - The status of playback
 * @param {string} playerStatus - The type of player to be used
 * @param {string} guideItem - The guide item that is rendering the play button
 * @param {string} tunedGuideId - The currently tuned guideId in the player
 * @param {string} externalHtmlPlayerUrl - The URL of the stream to play if playerStatus is 'popout'
 * @param {number} playbackHistory - The playback progress percentage of the current item
 */
const PlayButton = memo(
  ({
    syncedPlayerStatus,
    isMediaAdLoaded,
    canScrub,
    color,
    playerStatus,
    externalHtmlPlayerUrl,
    guideItem,
    tunedGuideId,
    playbackHistory,
    isDiscordPlayableSection,
  }) => {
    const guideId = guideItem?.guideId;
    /**
     * Check current status of player and display progress button
     * if player is connecting and/or media ad
     */
    if (syncedPlayerStatus === playerStatuses.connecting || isMediaAdLoaded) {
      return (
        <div className={css.playProgressContainer}>
          <CircularProgress
            data-testid="statusPlayerLoadingSpinner"
            color={color}
            size={35}
            thickness={1.5}
          />
        </div>
      );
    }

    /**
     * If there is a tunedGuideId in the player and the currentGuide item has a guideId
     * and they are the same and status is a popout, render and provide the stream URL
     */
    if (
      tunedGuideId &&
      guideId &&
      tunedGuideId === guideId &&
      playerStatus === playerStatuses.popout
    ) {
      return (
        <div className={css.popOutButton}>
          <PopOutButton
            externalHtmlPlayerUrl={externalHtmlPlayerUrl}
            dataTestId={`player-status-${playerStatus}`}
            width={styles.button.width}
            height={styles.button.height}
          />
        </div>
      );
    }

    if (playIconPlayerStatuses[syncedPlayerStatus]) {
      const { src, status } = getPlayedProgressSvgPath(
        playbackHistory,
        isDiscordPlayableSection,
      );
      return (
        <div
          className={css.playProgressContainer}
          data-icon="play"
          data-status={status}
        >
          <ReactSVG
            src={src}
            beforeInjection={(svg) => {
              // note: IE11 does not support Element.classlist for SVG elements
              svg.setAttribute('class', css.playButton);
              if (isDiscordPlayableSection) {
                svg.setAttribute('class', css.discordPlayableSection);
              }
            }}
          />
        </div>
      );
    }

    /**
     * Otherwise display a representational button based on current status
     */
    const PlayButtonStateRepresentation =
      playerStatusToSvgIconMap[syncedPlayerStatus](canScrub);
    const discordPlayableSectionProps = {
      fillBackgroundOpacity: '.2',
      fillButtonBackground: cssVariables['--anti-flash-white'],
      fillButton: cssVariables['--anti-flash-white'],
    };

    return (
      <div className={css.playProgressContainer}>
        <PlayButtonStateRepresentation
          id="listPlayerActionButton"
          dataTestId="listPlayerActionButton"
          className={classNames(css.playButton, {
            [css.discordPlayableSection]: isDiscordPlayableSection,
          })}
          width={styles.button.width}
          height={styles.button.height}
          {...(isDiscordPlayableSection && discordPlayableSectionProps)}
        />
      </div>
    );
  },
);

PlayButton.propTypes = {
  syncedPlayerStatus: PropTypes.string.isRequired,
  playbackStatus: PropTypes.object.isRequired,
  playbackHistory: PropTypes.number.isRequired,
  canScrub: PropTypes.bool,
  color: PropTypes.string.isRequired,
  guideItem: PropTypes.object,
  tunedGuideId: PropTypes.string,
  playerStatus: PropTypes.string,
  externalHtmlPlayerUrl: PropTypes.string,
};

export default PlayButton;
