import { useState } from 'react';
export default function useToggleState(initalValue = false) {
  const [state, setState] = useState(Boolean(initalValue));
  function toggleState() {
    setState((prevState) => !prevState);
  }
  function resetState() {
    setState(Boolean(initalValue));
  }
  return [state, toggleState, resetState];
}
