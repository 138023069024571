import PropTypes from 'prop-types';
import commonCss from 'src/common/styles/common.module.scss';
import LockedTile from '../../shared/LockedTile';
import css from '../../shared/guide-item.module.scss';
import Default from './Default';

const PremiumTile = (props) => (
  <LockedTile
    guideItem={props.guideItem}
    className={`${css.premiumTile} ${commonCss.overlay}`}
  >
    <Default {...props} isClickHandlerEnabled={false} />
  </LockedTile>
);

PremiumTile.propTypes = {
  guideItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedContentId: PropTypes.string,
};

export default PremiumTile;
