import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobileWeb from 'src/common/constants/analytics/categoryActionLabel/mobileWeb';
import connectWithPlayer from 'src/common/decorators/connectWithPlayer';
import { openDownloadAppDialogAndTrack } from '../actions/dialog';
import { logMobileWebActivity } from '../actions/logging';
import { mobileAppStoreRedirectCookie } from '../constants/cookies';
import { ENABLE_PROFILE_PLAY_BUTTON_REDIRECT_TO_APP_STORE } from '../constants/experiments/config';
import { playerStatuses } from '../constants/playerStatuses';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';
import { selectBranchUrl } from '../selectors/app';
import { selectExperiment } from '../selectors/config';
import { selectIsMediaAdLoaded } from '../selectors/player';
import { getCookie, setCookie } from '../utils/cookie';
import { isProfile } from '../utils/guideItemTypes';
import sendToAppDownload from '../utils/sendToAppDownload';

export default function withSyncedPlayerBehavior(PlayButtonComponent) {
  class ComponentWithSyncedPlayerBehavior extends Component {
    static propTypes = {
      branchUrl: PropTypes.string.isRequired,
      guideItem: PropTypes.object.isRequired,
      syncedPlayerAction: PropTypes.func.isRequired,
      syncedPlayerStatus: PropTypes.string.isRequired,
      isSyncedWithPlayer: PropTypes.bool.isRequired,
      isMediaAdLoaded: PropTypes.bool.isRequired,
      playerActions: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
      isMobile: PropTypes.bool.isRequired,
      logMobileWebActivity: PropTypes.func.isRequired,
      openDownloadAppDialogAndTrack: PropTypes.func.isRequired,
      canScrub: PropTypes.bool,
      enableProfilePlayButtonRedirect: PropTypes.bool,
    };

    static contextType = LocationAndLocalizationContext;

    processAppStoreRedirect(isMobileWebProfile) {
      const {
        branchUrl,
        enableProfilePlayButtonRedirect,
        syncedPlayerStatus,
        logMobileWebActivity: dispatchLogMobileWebActivity,
        isMediaAdLoaded,
      } = this.props;

      const hasCookie = getCookie(mobileAppStoreRedirectCookie.name);

      if (
        isMobileWebProfile &&
        enableProfilePlayButtonRedirect &&
        !hasCookie &&
        syncedPlayerStatus !== playerStatuses.playing &&
        !isMediaAdLoaded
      ) {
        setCookie(mobileAppStoreRedirectCookie.name, 1);
        sendToAppDownload(
          branchUrl,
          mobileWeb.labels.playButtonAppStoreRedirect,
          dispatchLogMobileWebActivity,
        );

        return true;
      }
    }

    handlePlayButtonClick = (to) => {
      const {
        playerActions,
        guideItem,
        canScrub,
        isMobile,
        isSyncedWithPlayer,
        syncedPlayerAction,
        history,
        openDownloadAppDialogAndTrack: dispatchOpenDownloadAppDialogAndTrack,
      } = this.props;

      const { location } = this.context;
      const { formats } = location.query;
      const isMobileWebProfile = isMobile && isProfile(guideItem.guideId);
      const redirectAttempted =
        this.processAppStoreRedirect(isMobileWebProfile);

      if (redirectAttempted) {
        return;
      }

      if (isSyncedWithPlayer) {
        syncedPlayerAction(playerActions, canScrub);
      } else {
        if (isMobileWebProfile) {
          dispatchOpenDownloadAppDialogAndTrack();
        }

        playerActions.tune({
          location,
          guideItem,
          formats: formats ? formats.toLowerCase() : formats,
        });
      }

      // Replace the route so that users navigate back to previous page via
      // browser back button instead of the previously played content.
      const pathname = get(to, 'pathname');
      if (to && pathname) {
        history.replace(to);
      }
    };

    render() {
      return (
        <PlayButtonComponent
          {...this.props}
          handlePlayButtonClick={this.handlePlayButtonClick}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    branchUrl: selectBranchUrl(state),
    enableProfilePlayButtonRedirect: selectExperiment(
      state,
      ENABLE_PROFILE_PLAY_BUTTON_REDIRECT_TO_APP_STORE,
    ),
    isMediaAdLoaded: selectIsMediaAdLoaded(state),
  });

  const mapDispatchToProps = {
    openDownloadAppDialogAndTrack,
    logMobileWebActivity,
  };

  return flow(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    connectWithPlayer,
  )(ComponentWithSyncedPlayerBehavior);
}
